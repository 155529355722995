import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';

import {flowManager} from "../managers/FlowManager";
import {useArticles} from "../hooks/useArticles";
import {StepHeader} from "../components/StepHeader";
import {ArticleList} from "../components/ArticleList";
import {AppsStep} from "./AppsStep/AppsStep";
import {AskTheCommunityStep} from "./AskTheCommunityStep";

KnowledgeBaseStep.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number
}

export function KnowledgeBaseStep(props) {
  const name = KnowledgeBaseStep.name;
  const stepRef = useRef(null);
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const [articles, isLoading, setUpdateArticles] = useArticles();
  const nextStep = steps[props.index]?.next?.Component?.name;
  const selectedApp = props?.data?.find(item => item.name === AppsStep.name);

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    setUpdateArticles(true);
  }, [setUpdateArticles]);

  function handleClick() {
    const action = "next";
    const entry = { name, action };
    flowManager.nextStep(props.index, entry);
  }

  return (
    <div ref={stepRef} tabIndex={0} className="support-form-step support-form-knowledge-base-step">
      <StepHeader
        index={props.index}
        question="This might help you."
        description="These articles may answer your question."
        isLoading={isLoading}
      />
      <ArticleList articles={articles} isLoading={isLoading} />

      {selectedApp?.outdated ? (
        <div className="support-form__warning is-visible">
          <span class="support-form__warning-icon"></span>
          <div>
            <span class="support-form__warning-title">The selected app is out of date.</span>
            <p class="support-form__warning-text"><a href={`https://homey.app/a/${selectedApp?.appId}`} className="text-decoration-underline">Click here</a> to update it to the latest version which may resolve your issue.</p>
          </div>
        </div>
      ) : (
        steps.length <= props.index + 1 && nextStep && (
          <React.Fragment>
            <h3 className="support-form-knowledge-base-step__subtitle">Still not found what you were looking for?</h3>
            {nextStep === AskTheCommunityStep.name ? (
              <AskTheCommunityStep nested />
            ) : (
              <React.Fragment>
                <p className="support-form-knowledge-base-step__text">Please feel free to reach out to us. We are happy to help!</p>
                <button type="button" className="support-form-knowledge-base-step__button" onClick={handleClick}>
                  <span>Contact Support</span>
                  <span className="mask-arrow-right"/>
                </button>
              </React.Fragment>
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
}
