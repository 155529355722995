import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../../managers/DataManager";
import {flowManager} from "../../managers/FlowManager";
import {dataKeys} from "../../enums/dataKeys";
import {useDevices} from "../../hooks/useDevices";
import {StepHeader} from "../../components/StepHeader";
import {useApps} from "../../hooks/useApps";
import {DeviceCard} from "./components/DeviceCard";

DevicesStep.propTypes = {
  index: PropTypes.number
}

export function DevicesStep(props) {
  const name = DevicesStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const stepRef = useRef(null);
  const [checkValue, setCheckValue] = useState("");
  const [devices, loadingDevices] = useDevices();
  const [apps, loadingApps] = useApps();
  const [appGroups, setAppGroups] = useState({});

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name]);

  useEffect(() => {
    if (apps && devices) {
      const appGroupsObject = {};

      Object.values(devices).forEach(device => {
        const deviceId = device?.driverId?.split(':').slice(0, -1).pop();
        const verifiedAuthor = apps[deviceId]?.details?.author?.verified;
        const matchingAppDevice = !!apps[deviceId];

        if (matchingAppDevice && verifiedAuthor) {
          appGroupsObject[deviceId] = {
            ...apps[deviceId],
            devices: {
              ...appGroupsObject[deviceId]?.devices,
              [device.id]: device
            }
          }
        }

        setAppGroups(appGroupsObject);
      });
    }
  }, [apps, devices]);

  function handleDeviceSelect(e) {
    const value = JSON.parse(e.target.value);
    const deviceName = value?.name || null;
    const driverId = value?.driverId || null;
    const deviceOwnerUri = value?.ownerUri?.split(':').pop();
    const deviceDriverId = value?.driverId?.split(':').slice(0, -1).pop();
    const deviceDriver = appGroups[deviceDriverId]?.details?.liveBuild?.drivers?.filter(driver => driver?.id === value?.driverId?.split(':')?.pop())[0];
    const deviceWithHomeySupport = appGroups[deviceOwnerUri]?.details?.liveBuild?.support?.includes('https://homey.app/support');
    const driverConnectivity = deviceDriver?.connectivity?.filter(connectivity => DevicesStep[connectivity]);
    const data = appGroups[deviceDriverId]?.data;
    const action = data?.action;

    let entry = { name, value: deviceName, driverId: driverId, ...data };

    // Set device knowledgebase/support step based on choses device.
    if (driverConnectivity?.includes('433mhz') || value?.driverId?.includes('screenshot:remote') || value?.driverId?.includes('screenshot:tv')) {
      entry = {...entry, ...DevicesStep[ '433mhz']};
    }
    if (driverConnectivity?.includes('868mhz')) {
      entry = {...entry, ...DevicesStep['868mhz']};
    }
    if (driverConnectivity?.includes('bluetooth') || value?.driverId?.includes('screenshot:speaker')) {
      entry = { ...entry, ...DevicesStep['bluetooth']};
    }
    if (driverConnectivity?.includes('infrared')) {
      entry = {...entry, ...DevicesStep['infrared']};
    }
    if (driverConnectivity?.includes('wifi') || value?.driverId?.includes('screenshot:camera')) {
      entry = {...entry, ...DevicesStep['wifi']};
    }
    if (driverConnectivity?.includes('zigbee') || value?.driverId?.includes('screenshot:light-static') ) {
      entry = {...entry, ...DevicesStep['zigbee']};
    }
    if (driverConnectivity?.includes('zwave') || value?.driverId.includes('screenshot:button') || value?.driverId.includes('screenshot:blinds')) {
      entry =  {...entry, ...DevicesStep['zwave']};
    }

    // Determine the support action based on the device and driver.
    if (deviceWithHomeySupport) {
      entry.action = DevicesStep.contactSupport.action;
    } else {
      entry.action = DevicesStep.contactDeveloperWithoutKnowledgeBase.action;
    }

    flowManager.nextActionStep(props.index, entry, action);
  }

  function getAttributesByAppId(appId) {
    const author = apps[appId]?.details?.author || null;
    const build = apps[appId]?.details?.liveBuild || apps[appId]?.details?.testBuild || null;

    if (!author || !build) return null;

    return {
      title: typeof build.name === 'string' ? build.name : build.name.en,
      color: build.brandColor,
      icon: build.icon,
      data: getDataAttribute(appId, build, author),
    }
  }

  // Check conditions to set the type of support. From top to bottom is most preferred to least preferred.
  function getDataAttribute(appId, build, author) {
    const supportedAuthors = {
      "Athom B.V.": "55db7e3cd4a9b01b3a198118",
      "IFTTT Inc.": "58c827599e636f1f0d049a78",
      "Koninklijke Auping B.V.": "5cff85e8b1ce4c0c5b45117f",
      "Nanoleaf Ltd.": "5a6614b44445b170349fd936",
      "Thermosmart B.V.": "589888416c26348e0d0c6b4c",
    }

    if (Object.values(supportedAuthors).includes(author.userId)) {
      return {
        ...DevicesStep.contactSupport,
        [dataKeys.appId]: appId
      }
    } else if (build.support && build.support.includes("https://")) {
      return {
        ...DevicesStep.contactDeveloper,
        [dataKeys.supportType]: "supportWebsite",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.support && build.support.includes("mailto:")) {
      return {
        ...DevicesStep.contactDeveloper,
        [dataKeys.supportType]: "email",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.bugs && build.bugs.includes("https://")) {
      return {
        ...DevicesStep.contactDeveloper,
        [dataKeys.supportType]: "bugsWebsite",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.homeyCommunityTopicId) {
      return {
        ...DevicesStep.contactDeveloper,
        [dataKeys.supportType]: "communityForum",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else {
      return {
        ...DevicesStep.contactDeveloper,
        [dataKeys.supportType]: "none",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    }
  }

  // Get device attributes
  Object.values(apps).forEach((app) => {
    const attributes = getAttributesByAppId(app.id);
    if (!attributes) return;

    if (appGroups[app.id]) {
      appGroups[app.id] = {
        ...appGroups[app.id],
        data: { ...attributes.data, [dataKeys.path]: ["Apps", attributes.title] },
      };
    }
  });

  return (
    <>
      <div ref={stepRef} tabIndex={0} className="support-form-step support-form-devices-step">
        <StepHeader
          index={props.index}
          question={"Which device is this about?"}
          description={"Choose the device your question or problem is related to."}
          isLoading={loadingApps}
        />

        {(loadingApps || loadingDevices) ? (
          <DeviceCard loading={true} />
        ) : (
          Object.values(appGroups)?.length ? Object.entries(appGroups).map(([key, app], idx) =>
            <DeviceCard
              key={key}
              idx={idx}
              app={app}
              checkValue={checkValue}
              handleSelect={handleDeviceSelect}
            />
          ) : (
            <div className="support-form-devices-step__offline">
              {devices.length === 0 ? "No devices installed on selected Homey." : "Couldn‘t establish a connection to your Homey. Please make sure it‘s connected and online."}
            </div>
          )
        )}
      </div>
      {steps.length === props.index + 1 && (
        <div className="support-form-step">
          <StepHeader
            index={props.index + 1}
            question="Select one of the above options."
          />
        </div>
      )}
    </>
  );
}

DevicesStep.contactSupport = {
  action: "contactSupport",
  articles: [360012588774],
  [dataKeys.tags]: []
}
DevicesStep.contactDeveloper = {
  action: "contactDeveloper",
  [dataKeys.tags]: []
}
DevicesStep.contactDeveloperWithoutKnowledgeBase = {
  action: "contactDeveloper",
  [dataKeys.tags]: []
}

// Drivers
DevicesStep["433mhz"] = {
  action: "433mhz",
  [dataKeys.articles]: [4409763123602, 360020540614],
  [dataKeys.tags]: ["433mhz"],
  [dataKeys.path]: ["433 MHz"]
}
DevicesStep["868mhz"] = {
  action: "868mhz",
  [dataKeys.articles]: [4409763154322],
  [dataKeys.tags]: ["868mhz"],
  [dataKeys.path]: ["868 MHz"]
}
DevicesStep.bluetooth = {
  action: "bluetooth",
  [dataKeys.articles]: [4409762463122],
  [dataKeys.tags]: ["bluetooth"],
  [dataKeys.path]: ["Bluetooth"]
}
DevicesStep.infrared = {
  action: "infrared",
  [dataKeys.articles]: [4409790615698],
  [dataKeys.tags]: ["infrared"],
  [dataKeys.path]: ["Infrared"]
}
DevicesStep.wifi = {
  action: "wifi",
  [dataKeys.articles]: [4409776821010, 360012077154],
  [dataKeys.tags]: ["wifi"],
  [dataKeys.path]: ["Wi-Fi"]
}
DevicesStep.zigbee = {
  action: "zigbee",
  [dataKeys.articles]: [4409769766418, 360019240259, 360015669840, 360019239879],
  [dataKeys.tags]: ["zigbee"],
  [dataKeys.path]: ["Zigbee"]
}
DevicesStep.zwave = {
  action: "zwave",
  [dataKeys.articles]: [4409762628114, 360012182213, 4410500384274],
  [dataKeys.tags]: ["z-wave"],
  [dataKeys.path]: ["Z-Wave"]
}