import React, { createRef, useEffect } from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Swiper, SwiperSlide } from 'swiper/react';

export function DeviceCard(props) {
  const detailsRef = createRef();
  const isSwiperMediaQuery = useMediaQuery("(hover: none), (max-width: 991px)");

  detailsRef.current = [];

  useEffect(() => {
    if (detailsRef.current && !props?.checkValue) {
      const firstRef = detailsRef.current[0];
      // Open first details after load
      if (firstRef) {
        setTimeout(() => {
            firstRef.open = true;
        }, 400);
      }
    }
  }, [detailsRef, props?.checkValue]);

  if(props.loading) {
    return (
      <details className="support-form-devices-step__group is-loading">
        <summary className="support-form-devices-step__group-summary">
          <span className="support-form-devices-step__group-icon">
            <span className="support-form-devices-step__group-icon-mask" />
          </span>
          <h4 className="support-form-devices-step__group-name">&nbsp;</h4>
          <span className="support-form-devices-step__group-marker glyph-core-chevron-down-bold"></span>
        </summary>
      </details>
    )
  } else {
    return (
      <details className="support-form-devices-step__group" ref={ref => detailsRef.current[props.idx] = ref}>
        <summary className="support-form-devices-step__group-summary">
          <span className="support-form-devices-step__group-icon" style={{ '--color': props.app?.details?.liveBuild?.brandColor }}>
            <span className="support-form-devices-step__group-icon-mask" style={{ maskImage: `url(${props.app?.details?.liveBuild?.icon})` }} />
          </span>
          <h4 className="support-form-devices-step__group-name">{props.app.details?.liveBuild?.name['en'] || props.app.details?.liveBuild?.name}</h4>
          <span className="support-form-devices-step__group-marker glyph-core-chevron-down-bold"></span>
        </summary>

        {isSwiperMediaQuery ? (
          <Swiper
            className="support-form-step-category__swiper support-form-step-category__swiper--devices"
            spaceBetween={12}
            slidesPerView={"auto"}
          >
            {Object.entries(props.app.devices)?.map(([key, device]) =>
              <SwiperSlide key={key}>
                <label className="support-form-devices-step__device">
                  <input type="radio" className="support-form-devices-step__device-radio" name="DeviceCategoryStep" value={JSON.stringify(device)} onChange={props?.handleSelect} />
                  <span className="support-form-devices-step__device-check-mark"></span>
                  {device?.iconObj && (
                    <img className="support-form-devices-step__device-img" src={device?.iconObj?.url} alt={device?.name} />
                  )}
                  <span className="support-form-devices-step__device-name">{device?.name}</span>
                </label>
              </SwiperSlide>
            )}
          </Swiper>
        ) : (
          <div className="support-form-devices-step__devices">
            {Object.entries(props.app?.devices)?.map(([key, device]) =>
              <label className="support-form-devices-step__device" key={key}>
                <input type="radio" className="support-form-devices-step__device-radio" name="DeviceCategoryStep" value={JSON.stringify(device)} onChange={props?.handleSelect} />
                <span className="support-form-devices-step__device-check-mark"></span>
                {device?.iconObj && (
                  <img className="support-form-devices-step__device-img" src={device?.iconObj?.url} alt={device?.name} />
                )}
                <span className="support-form-devices-step__device-name">{device?.name}</span>
              </label>
            )}
          </div>
        )}
      </details>
    )
  }
}