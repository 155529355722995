import {useEffect, useRef, useState} from "react";
import {userManager} from "../managers/UserManager";

// Get device details for selected Homey. Returns devices and loading state of the request.
export function useDevices() {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const ref = useRef(false);

  useEffect(() => {
    async function getDevices() {
      try {
        setLoading(true);
        const homey = userManager.getSelectedHomey();
        const homeyApi = await homey.authenticate();
        const homeyApiBaseUrl = await homeyApi.baseUrl;
        const awaitedHomeyApiDevices = await homeyApi.devices.getDevices();

        const deviceArray = Object.values(awaitedHomeyApiDevices);
        deviceArray.forEach((device, idx) => {
          deviceArray[idx] = {
            ...device,
            iconObj: {
              ...device?.iconObj,
              url: homeyApiBaseUrl + device?.iconObj?.url // Add homeyApi baseUrl for icon rendering
            }
          }
        });
        setDevices(deviceArray);
      } catch(error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (!ref.current) {
      ref.current = true;
      getDevices();
    }
  }, []);

  return [devices, loading];
}
