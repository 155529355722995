import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../../managers/DataManager";
import {flowManager} from "../../managers/FlowManager";
import {dataKeys} from "../../enums/dataKeys";
import {useApps} from "../../hooks/useApps";
import {StepHeader} from "../../components/StepHeader";
import {AppCheckbox} from "./components/AppCheckbox";

AppsStep.propTypes = {
  index: PropTypes.number
}

export function AppsStep(props) {
  const name = AppsStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const stepRef = useRef(null);
  const [checkValue, setCheckValue] = useState("");
  const [apps, isLoading] = useApps();
  const options = {};

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name])

  function handleSelect(e) {
    const value = e.target.value;
    const data = options[value].data;
    const entry = { name, value, ...data };
    const appId = entry?.appId;
    const installedAppVersion = apps[appId]?.version;
    const latestAppVersion = apps[appId]?.details?.liveVersion;
    let action = data.action;

    // Check if the app is outdated. If so, set the action to updateApp and show a message.
    if (installedAppVersion < latestAppVersion) {
      action = 'updateApp';
      entry.outdated = true;
    }

    flowManager.nextActionStep(props.index, entry, action);
  }

  function getAttributesByAppId(appId) {
    const author = apps[appId]?.details?.author || null;
    const build = apps[appId]?.details?.liveBuild || apps[appId]?.details?.testBuild || null;

    if (!author || !build) return null;

    return {
      title: typeof build.name === 'string' ? build.name : build.name.en,
      color: build.brandColor,
      icon: build.icon,
      data: getDataAttribute(appId, build, author),
    }
  }

  // Check conditions to set the type of support. From top to bottom is most preferred to least preferred.
  function getDataAttribute(appId, build, author) {
    const supportedAuthors = {
      "Athom B.V.": "55db7e3cd4a9b01b3a198118",
      "IFTTT Inc.": "58c827599e636f1f0d049a78",
      "Koninklijke Auping B.V.": "5cff85e8b1ce4c0c5b45117f",
      "Nanoleaf Ltd.": "5a6614b44445b170349fd936",
      "Thermosmart B.V.": "589888416c26348e0d0c6b4c",
    }

    if (Object.values(supportedAuthors).includes(author.userId)) {
      return {
        ...AppsStep.contactSupport,
        [dataKeys.appId]: appId
      }
    } else if (build.support && build.support.includes("https://")) {
      return {
        ...AppsStep.contactDeveloper,
        [dataKeys.supportType]: "supportWebsite",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.support && build.support.includes("mailto:")) {
      return {
        ...AppsStep.contactDeveloper,
        [dataKeys.supportType]: "email",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.bugs && build.bugs.includes("https://")) {
      return {
        ...AppsStep.contactDeveloper,
        [dataKeys.supportType]: "bugsWebsite",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else if (build.homeyCommunityTopicId) {
      return {
        ...AppsStep.contactDeveloper,
        [dataKeys.supportType]: "communityForum",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    } else {
      return {
        ...AppsStep.contactDeveloper,
        [dataKeys.supportType]: "none",
        [dataKeys.appBuild]: build,
        [dataKeys.appAuthor]: author,
        [dataKeys.appId]: appId
      }
    }
  }

  // Apps
  let appsIndex = 0;
  Object.entries(apps).forEach((app) => {
    // console.info("App info:", app);
    const attributes = getAttributesByAppId(app[0]);

    if (!attributes) return;

    options[`apps-${appsIndex}`] = {
      title: attributes.title,
      color: attributes.color,
      icon: attributes.icon,
      data: { ...attributes.data, [dataKeys.path]: ["Apps", attributes.title] }
    };
    appsIndex++;
  })

  options[`apps-${appsIndex}`] = {
    title: "Other",
    color: "var(--color-mono-050)",
    icon: "/img/pages/support-form/sub-category/other.svg",
    iconClass: "other",
    data: { ...AppsStep.notListed }
  };

  return (
    <>
      <div ref={stepRef} tabIndex={0} className="support-form-step support-form-apps-step">
        <StepHeader
          index={props.index}
          question={"Which app is this about?"}
          description={"Choose the app your question or problem is related to."}
          isLoading={isLoading}
        />
        {isLoading ? (
          <div className="support-form-apps-step__apps">
            <AppCheckbox isLoading/>
            <AppCheckbox isLoading/>
            <AppCheckbox isLoading/>
            <AppCheckbox isLoading/>
            <AppCheckbox isLoading/>
          </div>
        ) : (
          <div className="support-form-apps-step__apps">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("apps") && (
                <AppCheckbox
                  key={value}
                  name={name}
                  value={value}
                  check={checkValue}
                  title={option.title}
                  color={option.color}
                  icon={option.icon}
                  iconClass={option.iconClass}
                  onChange={handleSelect}>
                </AppCheckbox>
              )
            })}
          </div>
        )}
      </div>
      {steps.length === props.index + 1 && (
        <div className="support-form-step">
          <StepHeader
            index={props.index + 1}
            question="Select one of the above options."
          />
        </div>
      )}
    </>
  );
}

AppsStep.updateApp = {
  action: "updateApp",
  [dataKeys.articles]: [360012588774],
  [dataKeys.tags]: []
}

AppsStep.contactSupport = {
  action: "contactSupport",
  [dataKeys.articles]: [360012588774],
  [dataKeys.tags]: []
}

AppsStep.contactDeveloper = {
  action: "contactDeveloper",
  [dataKeys.articles]: [360012588774],
  [dataKeys.tags]: []
}

AppsStep.notListed = {
  action: "appNotListed",
  [dataKeys.articles]: [360012079034, 360012588774, 14090087954460],
  [dataKeys.tags]: ["not-listed"],
  [dataKeys.path]: ["Apps", "Other"]
}
